import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'

import { Container, Row, Column, Stack } from '../components/Layout'

import Heading from '../components/Heading'
import Text from '../components/Text'
import Link from '../components/Link'

const LinkWrapper = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`

const CaseStudyCard = ({ img, title, href, description, index }) => (
  <div href={href}>
    {index & 1 ? ( // Even or Odd
      <Row
        columns={12}
        verticalSpacing={[10, 0]}
        style={{ alignItems: 'center' }}
      >
        <Column span={5}>
          <Img fluid={img} alt={title} />
        </Column>
        <Column offset={8} span={5}>
          <Heading level={2}>{title}</Heading>
          <Text>{description}</Text>
          <Link
            style={{ marginTop: '8px', display: 'inline-block' }}
            href={href}
          >
            View Case Study
          </Link>
        </Column>
      </Row>
    ) : (
      <Row
        columns={12}
        verticalSpacing={[10, 0]}
        style={{ alignItems: 'center' }}
      >
        <Column span={5}>
          <Heading level={2}>{title}</Heading>
          <Text>{description}</Text>
          <Link
            style={{ marginTop: '8px', display: 'inline-block' }}
            href={href}
          >
            View Case Study
          </Link>
        </Column>
        <Column offset={8} span={5}>
          <Img fluid={img} alt={title} />
        </Column>
      </Row>
    )}
  </div>
)

export default CaseStudyCard
