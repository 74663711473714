import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Img from 'gatsby-image'
import { Link as ScrollLink } from 'react-scroll'

import { Container, Row, Column, Stack } from '../components/Layout'
import Header from '../components/Header'
import Heading from '../components/Heading'
import CaseStudyCard from '../components/CaseStudyCard'
import Text from '../components/Text'
import Footer from '../components/Footer'
import Button from '../components/Button'

const Home = ({ data }) => (
  <div className="text-sans text-primary bg-light-blue">
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.siteInfo.siteMetadata.title}</title>
      <link rel="canonical" href={data.siteInfo.siteMetadata.url} />
      <meta
        name="description"
        content={data.siteInfo.siteMetadata.description}
      />
      <meta
        name="viewport"
        content="width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
      />
    </Helmet>
    <Header
      title="Hey, I’m Hannah—a visual designer who is passionate about the intersection of digital product design & human behavior. "
      intro="Empathy is a core component of self, fostered by my time studying developmental psychology and social frameworks. "
      // link={<Button>Contact Me</Button>}
    />
    <Container>
      <Row verticalSpacing={[10, 0]}>
        <Column span={12}>
          <Heading level={1.5} as="h2">
            Case Studies
          </Heading>
          <div className="space-y-6">
            {data.caseStudies.edges.map(({ node }, index) => (
              <CaseStudyCard
                img={node.frontmatter.thumbnail.childImageSharp.fluid}
                title={node.frontmatter.title}
                href={node.fields.slug}
                description={node.frontmatter.description}
                index={index}
              />
            ))}
          </div>
        </Column>
      </Row>
      <Row verticalSpacing={[10, 0]}>
        <Column span={12}>
          <Stack space={4}>
            <Heading level={1.5} as="h2">
              Iconography & Illustrative Work
            </Heading>
            <Row columns={12} rowGap={1.5}>
              {data.iconAndIllustrationImages.edges.map(image => (
                <Column span={4} key={image.node.name}>
                  <Img
                    fluid={image.node.childImageSharp.fluid}
                    alt={image.node.name}
                    style={{ borderRadius: '8px' }}
                  />
                </Column>
              ))}
            </Row>
          </Stack>
        </Column>
      </Row>
    </Container>
    <Footer />
  </div>
)
export const query = graphql`
  query HomePageQuery {
    siteInfo: site {
      siteMetadata {
        name
        title
        description
        url
      }
    }

    caseStudies: allJavascriptFrontmatter(
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            description
            title
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 600, quality: 80) {
                  aspectRatio
                  src
                  srcSet
                  srcWebp
                  srcSetWebp
                  sizes
                }
              }
            }
          }
        }
      }
    }

    iconAndIllustrationImages: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { regex: "/(iconography)|(illustration)/" }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          base
          name
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
            }
          }
        }
      }
    }
  }
`

export default Home
